<!-- 

	@component
	@dperecated Not currently in use.

-->
<script context="module" lang="ts">
	export let SCROLL_TO_TOP_BREAKPOINT = 1000;

	export const scrollPageToTop = ({ isMobile, isPwa }: { isMobile: boolean; isPwa: boolean }) => {
		logger.debug('Scrolling page to top...');

		// Mobile...
		if (isMobile && !isPwa) {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			});
			return;
		}
		// Tablet && PWA...
		const el = document.querySelector('#page');
		el?.scrollTo({
			behavior: 'smooth',
			top: 0
		});
	};
</script>

<script lang="ts">
	import type { AppShell } from '@skeletonlabs/skeleton';

	import { logger } from '@tickrr/lib/logger';
	import { type ComponentEvents, onMount } from 'svelte';
	import { fade } from 'svelte/transition';

	import { getStores } from '../stores';

	const { isMobileStore } = getStores(['isMobileStore']);

	export let isPwa: boolean;

	let windowScrollY: number = 0;
	let pageScrollY: number = 0;

	$: isMobile = $isMobileStore;
	$: isMobileBrowser = isMobile && !isPwa;
	$: displayScrollToTopButton = isMobileBrowser
		? windowScrollY > SCROLL_TO_TOP_BREAKPOINT
		: pageScrollY > SCROLL_TO_TOP_BREAKPOINT;

	// Attach handler on mount.
	const attachHandler = () => {
		const el: HTMLElement | null = document.querySelector('#page');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handler: any = (event: ComponentEvents<AppShell>['scroll']) => {
			pageScrollY = (event?.currentTarget as HTMLElement).scrollTop;
		};
		if (el) el.onscroll = handler;
	};

	onMount(() => {
		attachHandler();
	});
</script>

<svelte:window bind:scrollY={windowScrollY} />

{#if displayScrollToTopButton}
	<button
		id="scroll-back-to-top-button"
		aria-hidden={!displayScrollToTopButton}
		aria-label="Scroll back to top"
		data-testid="scroll-back-to-top-button"
		type="button"
		class="
		variant-outline-surface
		variant-filled
		btn-icon
		fixed
		bottom-[3.5rem]
		right-2
		z-[10000]
		rounded-xl
		shadow-xl
		!ring-2
		"
		class:max-tablet:!bottom-[4.5rem]={isPwa}
		class:max-tablet:!right-[12.5%]={isPwa}
		class:max-tablet:!translate-x-[50%]={isPwa}
		on:click={() => scrollPageToTop({ isMobile, isPwa })}
		transition:fade={{ duration: 300 }}
	>
		<iconify-icon icon="tabler:chevron-up" height="24px" />
		<span class="sr-only">Scroll back to top</span>
	</button>
{/if}
