<script context="module" lang="ts">
	// The duration must coincide with the duration set in mobileDrawerSettings.ts.
	// And the translate-x value must coincide with the width of the drawer which can be found in the same place.
	const MOBILE_SIDEBAR_ANIMATION_CLASSES = 'duration-300 translate-x-[16rem]';
</script>

<script lang="ts">
	import { type SvelteEvent, getDrawerStore } from '@skeletonlabs/skeleton';

	import { getIsPwaStore, getStores } from '../stores';
	import ScrollBackToTopButton from './ScrollBackToTopButton.svelte';

	const drawerStore = getDrawerStore();
	const isPwaStore = getIsPwaStore();
	const { rtpWebsocketClientStore } = getStores(['rtpWebsocketClientStore']);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface $$Events {
		scroll: SvelteEvent<UIEvent, HTMLDivElement>;
	}

	let isPwa: boolean = $isPwaStore;
	let innerWidth: number;

	/**
	 * Do not set overflow-hidden values on mobile size for any reason.
	 * It will break StickyContainer/StickyHeader functionality.
	 */
	const appShellClasses = {
		default: 'flex flex-col w-full min-h-full h-full tablet:overflow-hidden',
		pwa: 'flex flex-col w-full h-full overflow-hidden'
	};
	const contentAreaClasses = {
		default: 'w-full h-full flex tablet:overflow-hidden',
		pwa: 'w-full h-full flex overflow-hidden'
	};
	const pageClasses = {
		default: 'flex-1 flex flex-col tablet:overflow-x-hidden',
		pwa: 'flex-1 flex flex-col overflow-x-hidden overflow-y-auto'
	};

	// Reactive Classes
	$: isPwa = $isPwaStore;
	$: isMobileSidebarOpen = $drawerStore.open && $drawerStore.id === 'mobile-sidebar';
	$: isRtpWsConnectedStore = $rtpWebsocketClientStore.isConnected;

	$: cBaseAppShell = isPwa ? appShellClasses.pwa : appShellClasses.default;
	$: cPage = isPwa ? pageClasses.pwa : pageClasses.default;
	$: cContentArea = isPwa ? contentAreaClasses.pwa : contentAreaClasses.default;
	$: cDrawerAnimation = isMobileSidebarOpen ? MOBILE_SIDEBAR_ANIMATION_CLASSES : '';
</script>

<svelte:window bind:innerWidth />

<div
	id="appShell"
	data-is-rtp-ws-connected={$isRtpWsConnectedStore}
	data-testid="app-shell"
	class="{cBaseAppShell} {$$props.class ?? ''} transition-transform {cDrawerAnimation}"
>
	<!-- Slot: Header -->
	{#if $$slots['header']}
		<header id="shell-header" class="z-50 flex-none">
			<slot name="header"><!-- optional fallback --></slot>
		</header>
	{/if}

	<!-- Content Area -->
	<div id="content-area" class="flex-auto {cContentArea}">
		<!-- Slot: Sidebar (left) -->
		{#if $$slots['sidebarLeft']}
			<aside id="sidebar-left" class="relative !z-10 w-auto flex-none overflow-visible">
				<slot name="sidebarLeft"><!-- optional fallback --></slot>
			</aside>
		{/if}

		<!-- Page -->
		<div id="page" class={cPage} on:scroll>
			<!-- Slot: Page Header -->
			<!-- 
				Note: how page-header is *always* renderd compared to all other slots.
				That is because it houses our PullToRefresh element, which is needed 
				on all mobile devices.
			-->
			<header id="page-header" class="sticky top-0 z-10 flex-none">
				<slot name="pullToRefresh"><!-- optional fallback --></slot>
				<slot name="pageHeader"><!-- optional fallback --></slot>
			</header>

			<!-- 
				Note: this must be placed outside of the below elements.
				Fixed positioning ndoes not work within a '@container...'
			-->
			<ScrollBackToTopButton {isPwa} />

			<main id="page-content" class="relative flex-auto @container">
				<slot name="navigationIndicator"><!-- optional fallback --></slot>

				<div class="flex h-full min-h-fitheader flex-col">
					<slot />
				</div>
			</main>

			<!-- Slot: Page Footer -->
			{#if $$slots['pageFooter']}
				<footer id="page-footer" class="flex-none">
					<slot name="pageFooter"><!-- optional fallback --></slot>
				</footer>
			{/if}
		</div>

		<!-- Slot: Sidebar (right) -->
		<aside id="sidebar-right" class="w-auto flex-none overflow-y-auto overflow-x-hidden">
			<slot name="sidebarRight"><!-- optional fallback --></slot>
		</aside>
	</div>

	<!-- Slot: footer -->
	{#if $$slots['footer']}
		<footer id="shell-footer" class="flex-none">
			<slot name="footer" />
		</footer>
	{/if}
</div>
